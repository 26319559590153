import { EmptyPosthogProvider } from '@/components/v2/common/PostHog/empty-provider';
import { SubscriptionState } from '@/hooks/useSubscriptionStatus';
import { ConnectorInfo, CoreBase, ExternalBase } from '@/lib/client/v1/entities';
import { Flavor, getBuildFlavor } from '@/utils/flags';
import defaultPosthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

export enum PostHogEvents {
  PAGE_VIEW = '$pageview',
  SYNC_CREATION_START = 'sync_creation_start',

  SYNC_SAVE_EXTERNAL_BASE_1_START = 'sync_save_external_base_1_start',
  SYNC_SAVE_EXTERNAL_BASE_2_START = 'sync_save_external_base_2_start',
  SYNC_SAVE_EXTERNAL_BASE_1_SUCCESS = 'sync_save_external_base_1_success',
  SYNC_SAVE_EXTERNAL_BASE_2_SUCCESS = 'sync_save_external_base_2_success',
  SYNC_SAVE_EXTERNAL_BASE_1_FAILURE = 'sync_save_external_base_1_failure',
  SYNC_SAVE_EXTERNAL_BASE_2_FAILURE = 'sync_save_external_base_2_failure',

  SYNC_AUTHORIZE_APP_1_BUTTON_CLICK = 'sync_authorize_app_1_button_click',
  SYNC_AUTHORIZE_APP_1_CALLBACK = 'sync_authorize_app_1_callback',
  SYNC_AUTHORIZE_APP_1_SUCCESS = 'sync_authorize_app_1_success',
  SYNC_AUTHORIZE_APP_1_FAILURE = 'sync_authorize_app_1_failure',
  SYNC_AUTHORIZE_APP_2_BUTTON_CLICK = 'sync_authorize_app_2_button_click',
  SYNC_AUTHORIZE_APP_2_CALLBACK = 'sync_authorize_app_2_callback',
  SYNC_AUTHORIZE_APP_2_SUCCESS = 'sync_authorize_app_2_success',
  SYNC_AUTHORIZE_APP_2_FAILURE = 'sync_authorize_app_2_failure',

  SYNC_CONTINUE_TO_TABLE_MAPPINGS = 'sync_continue_to_table_mappings',
  SYNC_CONTINUE_TO_SELECT_TABLES = 'sync_continue_to_select_tables',
  SYNC_CONTINUE_TO_PREVIEW = 'sync_continue_to_preview',
  SYNC_CONTINUE_BEYOND_PREVIEW = 'sync_continue_beyond_preview',
  SYNC_CONTINUE_FROM_RECORD_MATCHING = 'sync_continue_from_record_matching',
  SYNC_CONTINUE_UNKNOWN_STEP = 'sync_continue_unknown_step',
  SYNC_CONTINUE_ERROR = 'sync_continue_error',

  SYNC_CREATE_TABLE_MAPPINGS_BUTTON_CLICK = 'sync_create_table_mappings_button_click',
  SYNC_CREATE_TABLE_MAPPINGS_SUCCESS = 'sync_create_table_mappings_success',
  SYNC_CREATE_TABLE_MAPPINGS_FAILURE = 'sync_create_table_mappings_failure',

  SYNC_AUTO_GEN_TABLE_BUTTON_CLICK = 'sync_auto_gen_table_button_click',
  SYNC_AUTO_GEN_TABLE_BUTTON_CANCEL = 'sync_auto_gen_table_button_cancel',
  SYNC_AUTO_GEN_CREATE_TABLES_SUCCESS = 'sync_auto_gen_create_tables_success',
  SYNC_AUTO_GEN_CREATE_TABLES_FAILURE = 'sync_auto_gen_create_tables_failure',
  SYNC_AUTO_GEN_CREATE_TABLES_RETRY_ATTEMPT = 'sync_auto_gen_create_tables_retry_attempt',
  SYNC_AUTO_GEN_CREATE_TABLE_MAPPINGS_SUCCESS = 'sync_auto_gen_create_table_mappings_success',
  SYNC_AUTO_GEN_CREATE_TABLE_MAPPINGS_FAILURE = 'sync_auto_gen_create_table_mappings_failure',
  SYNC_SWITCH_SIMPLE_MODE_BUTTON_CLICK = 'sync_switch_simple_mode_button_click',
  SYNC_SWITCH_ADVANCED_MODE_SWITCH_BUTTON_CLICK = 'sync_switch_advanced_mode_switch_button_click',

  SYNC_AUTO_MAP_TABLE_MAPPINGS_BUTTON_CLICK = 'sync_auto_map_table_mappings_button_click',
  SYNC_AUTO_MAP_TABLE_MAPPINGS_SKIP_BUTTON_CLICK = 'sync_auto_map_table_mappings_skip_button_click',

  SYNC_SAVE_TABLE_MAPPINGS = 'sync_save_table_mappings',

  SYNC_PREPARE_INITIAL_SYNC_CLICK = 'sync_prepare_initial_sync_click',
  SYNC_PREPARE_INITIAL_SYNC_FAILURE = 'sync_prepare_initial_sync_failure',
  SYNC_SCAN_RECORDS = 'sync_scan_records',
  SYNC_SCAN_COMPLETE = 'sync_scan_complete',
  SYNC_ACTIVATED_BUTTON_CLICK = 'sync_activated_button_click',
  SYNC_ACTIVATED_CONFIRMATION_BUTTON_CLICK = 'sync_activated_confirmation_button_click',
  SYNC_CANCEL_INITIAL_SYNC = 'sync_cancel_initial_sync',

  BOOK_DEMO = 'book_demo',

  BOOK_CLOSED_BETA_CALL = 'book_closed_beta_call',

  ACCOUNT_USER_SIGN_OUT = 'account_user_sign_out',

  ISSUES_RETRY_ALL = 'issues_retry_all',

  CONNECT_DATA_SOURCE = 'connect_data_source',
  CONNECTION_REAUTHORIZED = 'connection_reauthorization',
}

type ExternalBaseInfo = {
  id: string | null;
  connectorType: string | null;
};

type TableMappingDetailData = {
  total_tables_mapped: number;
  total_columns_mapped: number;
  tables: Record<string, unknown>[];
};

type CoreBaseEventData = {
  core_base_id: CoreBase['id'];
  core_base_name: CoreBase['name'];
  external_base_1: ExternalBaseInfo;
  external_base_2: ExternalBaseInfo;
  mapped_table_details: TableMappingDetailData | null;
};

type ConnectorEventData = {
  app_name: ConnectorInfo['displayName'];
  app_type: ConnectorInfo['connectorType'];
  auth_type: ConnectorInfo['auth']['type'];
  required_auth_fields: number;
  additional_setup_fields: number;
  is_beta_app: ConnectorInfo['isInBeta'];
  [key: string]: unknown;
};

type SubscriptionEventProperties = {
  subscription_status: SubscriptionState['status'];
  plan_name: SubscriptionState['planDisplayName'];
  days_remaining: SubscriptionState['daysRemaining'];
  sync_count: SubscriptionState['baseCount'];
  record_count: SubscriptionState['recordCount'];
  record_limit: SubscriptionState['recordLimit'];
  record_limit_reached: SubscriptionState['recordLimitReached'];
};

export function getTableMappingDetails(coreBase?: CoreBase | null): TableMappingDetailData {
  if (!coreBase) {
    return { total_tables_mapped: 0, total_columns_mapped: 0, tables: [] };
  }

  const tables: Record<string, unknown>[] = [];
  let totalColumns = 0;
  coreBase.mappings?.tableMappings.forEach((tableMapping) => {
    const leftTable = coreBase.leftExternalBase?.externalTables?.find((table) => table.id === tableMapping.leftTableId);
    const rightTable = coreBase.rightExternalBase?.externalTables?.find(
      (table) => table.id === tableMapping.rightTableId,
    );

    const columns: Record<string, unknown>[] = tableMapping.columnMappings.map((c) => {
      const leftColumn = leftTable?.externalColumns?.find((column) => column.id === c.leftColumnId);
      const rightColumn = rightTable?.externalColumns?.find((column) => column.id === c.rightColumnId);

      return {
        left_column: leftColumn?.name,
        left_column_id: c.leftColumnId,
        right_column: rightColumn?.name,
        right_column_id: c.rightColumnId,
        sync_direction: c.syncDirection,
      };
    });
    totalColumns += columns.length;
    tables.push({
      id: tableMapping.id,
      left_table_id: tableMapping.leftTableId,
      right_table_id: tableMapping.rightTableId,
      left_table: leftTable?.name,
      right_table: rightTable?.name,
      sync_direction: tableMapping.syncDirection,
      has_filter: tableMapping.filter !== null,
      num_columns_mapped: columns.length,
      columns,
    });
  });

  return { total_tables_mapped: tables.length, total_columns_mapped: totalColumns, tables };
}

export function getCoreBaseEventProperties(coreBase: CoreBase): CoreBaseEventData {
  const getExternalBaseInfo = (externalBase: ExternalBase | null): ExternalBaseInfo => ({
    id: externalBase?.id ?? null,
    connectorType: externalBase?.connectorInfo?.connectorType ?? null,
  });

  return {
    core_base_id: coreBase.id,
    core_base_name: coreBase.name,
    external_base_1: getExternalBaseInfo(coreBase.leftExternalBase),
    external_base_2: getExternalBaseInfo(coreBase.rightExternalBase),
    mapped_table_details: getTableMappingDetails(coreBase),
  };
}

export function getConnectorEventProperties(connectorInfo: ConnectorInfo): ConnectorEventData {
  return {
    app_name: connectorInfo.displayName,
    app_type: connectorInfo.connectorType,
    auth_type: connectorInfo.auth.type,
    required_auth_fields: 'requiredItems' in connectorInfo.auth ? (connectorInfo.auth.requiredItems?.length ?? 0) : 0,
    additional_setup_fields: connectorInfo.requiredBaseExtras.length,
    is_beta_app: connectorInfo.isInBeta,
  };
}

export function getSubscriptionEventProperties(subscriptionState: SubscriptionState): SubscriptionEventProperties {
  return {
    subscription_status: subscriptionState.status,
    plan_name: subscriptionState.planDisplayName,
    days_remaining: subscriptionState.daysRemaining,
    sync_count: subscriptionState.baseCount,
    record_count: subscriptionState.recordCount,
    record_limit: subscriptionState.recordLimit,
    record_limit_reached: subscriptionState.recordLimitReached,
  };
}

type EventProperties = Partial<{
  core_base: CoreBase;
  subscription: SubscriptionState;
  connector_info: ConnectorInfo;
}> &
  Record<string, unknown>;

export function captureEvent(eventName: PostHogEvents, properties: EventProperties = {} as EventProperties): void {
  // Analytics functions should NEVER throw errors
  try {
    const mappedProperties: Record<string, unknown> = {};

    if ('core_base' in properties && properties.core_base) {
      Object.assign(mappedProperties, getCoreBaseEventProperties(properties.core_base as CoreBase));
    }

    if ('connector' in properties && properties.connector_info) {
      Object.assign(mappedProperties, getConnectorEventProperties(properties.connector_info as ConnectorInfo));
    }

    if ('subscription' in properties && properties.subscription) {
      Object.assign(mappedProperties, getSubscriptionEventProperties(properties.subscription as SubscriptionState));
    }

    // Include all other properties, including any additional custom parameters
    Object.entries(properties).forEach(([key, value]) => {
      if (!['core_base', 'subscription', 'connector'].includes(key)) {
        mappedProperties[key] = value;
      }
    });

    defaultPosthog.capture(eventName, mappedProperties);
  } catch (e) {
    console.error('Failed to capture event', e);
  }
}

/**
 * Initializes PostHog analytics.
 *
 * This function attempts to initialize PostHog if the necessary conditions are met
 * (browser environment, PostHog key available). It returns either:
 *
 * 1. PostHogProvider: If PostHog is successfully initialized.
 * 2. DummyPosthogProvider: If PostHog cannot be initialized.
 *
 * Returning DummyPosthogProvider when PostHog isn't initialized allows the calling code
 * to always use the result as a component, maintaining consistent structure
 * regardless of whether PostHog is active or not.
 *
 * @returns {typeof PostHogProvider | typeof EmptyPosthogProvider} The appropriate React component
 */
export function initializePostHog(): typeof PostHogProvider | typeof EmptyPosthogProvider {
  // Check that PostHog is client-side (used to handle Next.js SSR)
  if (typeof window !== 'undefined') {
    const postHogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;

    if (postHogKey) {
      defaultPosthog.init(postHogKey, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
        person_profiles: 'identified_only',
        // Learn more about autocapture: https://posthog.com/docs/product-analytics/autocapture
        autocapture: {
          css_selector_allowlist: ['[ph-autocapture]'],
        },
        // Enable session recording
        session_recording: {
          blockClass: 'no-record', // CSS class to block elements from being recorded
          maskTextClass: 'mask-text', // CSS class to mask text content within an element
          maskAllInputs: true, // Mask all input fields by default
          maskInputOptions: {
            password: true, // Mask password inputs
            email: true, // Mask email inputs
          },
          collectFonts: false, // Set to true if you want to collect font files
          inlineStylesheet: true, // Include external stylesheets in the recording
          recordCrossOriginIframes: true, // Enable recording for cross-origin iframes
          compress_events: true, // Compress events to save bandwidth
          recordHeaders: true, // Record request headers in network requests
          recordBody: false, // Disable recording of request bodies in network requests
          maskCapturedNetworkRequestFn: (request) => {
            // Modify or mask specific captured network request data
            // Example: Masking sensitive data
            if (request.name && request.name.includes('sensitive')) {
              return null; // Exclude this request from being recorded
            }
            return request;
          },
        },
        // Enable debug mode in development
        loaded: (posthog) => {
          posthog.register({ site_type: 'app' });

          if (getBuildFlavor() === Flavor.Local) posthog.debug();
        },
      });

      return PostHogProvider;
    }
  }

  return EmptyPosthogProvider;
}
