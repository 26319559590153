import { UUID } from '@/lib/client/v1/entities';

import { NewFlowStepId } from '@/routes/new-sync-flow/types';
import { SyncPreviewStep } from '@/routes/sync-preview/types';
import { Flavor } from './flags';

export class RouteUrls {
  static v1DevComponentPlaygroundUrl = '/v1/dev/component-playground';
  static v1DevTokenUrl = '/v1/dev/token';
  static v1IssuesPageUrl = '/v1/issues';
  static v1LogsPageUrl = '/v1/logs';
  static v1DashboardUrl = '/v1/dashboard';
  static v1ManageSubscription = '/v1/account/manage';
  static v1ProfilePageUrl = '/v1/account/profile';
  static v1SignInPageUrl = '/v1/sign-in';
  static v1SignUpPageUrl = '/v1/sign-up';

  // V2 URLs.
  static syncs = '/syncs';
  static newSync = '/syncs/new';
  static issues = '/issues';
  static operations = '/operations';
  static devOptions = '/developer';
  static connections = '/connections';
  static getStarted = '/get-started';
  static settings = '/settings';
  static manageSubscription = '/subscription/manage';
  static profile = '/settings/profile';
  static devTools = '/settings/dev-tools';
  static allQueues = '/settings/all-queues';
  static devToolsFlags = '/settings/dev-tools/flags';
  static devToolsUsers = '/settings/dev-tools/users';
  static devToolsStuckInitSyncs = '/settings/dev-tools/stuck-syncs';
  static devToolsJobViewerHome = '/settings/dev-tools/job-viewer';

  static helpAndSupport = '/help-and-support';
  static accountPayments = '/account/payment';
  static accountSignup = '/account/signup';
  static accountSignupPattern = '/account/signup/(.*)';
  static signIn = '/sign-in';
  static signUp = '/sign-up';

  // Public routes
  static healthPageUrl = '/health';
  static helloApiUrl = '/api/hello';

  // Used in DetailLayout. It does not accept UrlObject

  // Temporarely we do not show the overview so we go to the TM page directly
  static syncRoutePattern = '/syncs/[id]';
  static syncDevToolsRoutePattern = '/syncs/[id]/dev-tools';
  static syncUrl = (coreBaseId: UUID): string => `/syncs/${coreBaseId}`;
  static syncOverview = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/overview`;
  static syncOperations = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/operations`;
  static syncIssues = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/issues`;
  static syncGetStarted = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/setup`;
  static syncDevTools = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/dev-tools`;
  static syncTraceLogs = ({ coreBaseId, traceId, q }: { coreBaseId: UUID; q?: string; traceId?: string }): string =>
    `/syncs/${coreBaseId}/trace-logs` + assembleOptionalQueryString({ q, traceId });
  static syncSelectTables = ({ coreBaseId }: { coreBaseId: UUID }): string => `/syncs/${coreBaseId}/select-tables`;

  static syncPreview = ({
    coreBaseId,
    stepId = SyncPreviewStep.SCAN_RECORDS,
  }: {
    coreBaseId: string;
    stepId?: SyncPreviewStep;
  }): string => `/syncs/${coreBaseId}/sync-preview/${stepId}`;

  static syncEdit = ({ coreBaseId, step }: { coreBaseId: UUID; step?: number }): string =>
    `/syncs/edit/${coreBaseId}` + assembleOptionalQueryString({ step });

  static tableMappings = ({ coreBaseId, isNew }: { coreBaseId: string; isNew?: boolean }): string =>
    `/syncs/${coreBaseId}/table-mappings` +
    assembleOptionalQueryString({ init: isNew ? `${new Date().getTime()}` : undefined });

  static tableMapping = ({
    coreBaseId,
    tableMappingId,
    isNew,
  }: {
    coreBaseId: string;
    tableMappingId: string;
    isNew: boolean;
  }): string =>
    `/syncs/${coreBaseId}/table-mappings/${tableMappingId}/field-mappings` +
    assembleOptionalQueryString({ init: isNew ? `${new Date().getTime()}` : undefined });

  static tableMappingEditor = ({
    coreBaseId,
    tableMappingId,
    isNew,
  }: {
    coreBaseId: string;
    /** falsy converted to 0 to satisfy NextJS path resolvinbg*/
    tableMappingId?: string;
    isNew?: boolean;
  }): string =>
    `/syncs/${coreBaseId}/table-mapping-editor/${Boolean(tableMappingId) ? tableMappingId : '0'}` +
    assembleOptionalQueryString({ init: isNew ? `${new Date().getTime()}` : undefined });

  static issue = (id: string): string => `/issues/${id}`;

  static operation = (id: string): string => `/operations/${id}`;

  static paymentPageWithProductType = (productType: string): string =>
    `${RouteUrls.accountPayments}/${encodeURIComponent(productType)}`;

  static accountSignupWithProductType = (productType: string): string =>
    `${RouteUrls.accountSignup}/${encodeURIComponent(productType)}`;

  static signInPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signIn}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static signUpPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signUp}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static issuesForBasePageUrl(coreBaseId: string): string {
    const filters = {
      coreBaseId,
    };

    return `${this.issues}?filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  static devToolSchemaViewer(coreBaseId: string): string {
    return `${RouteUrls.syncDevTools(coreBaseId as UUID)}/schema`;
  }
  static devToolJobViewer(externalBaseId?: string): string {
    const filters = externalBaseId ? `externalBaseId=${externalBaseId}` : '';
    return `${RouteUrls.devToolsJobViewerHome}?${filters}`;
  }

  static newSyncEdit(coreBaseId: string, stepId?: NewFlowStepId, extraParams?: Record<string, string>): string {
    const newSyncStepURL = `/syncs/edit/${coreBaseId}${stepId ? `/${stepId}` : ''}`;

    if (extraParams) {
      const queryString = assembleOptionalQueryString(extraParams);
      return `${newSyncStepURL}${queryString}`;
    }

    return newSyncStepURL;
  }

  static newSyncFlow = `/syncs/new`;

  static syncDetail = (coreBaseId?: string): string =>
    coreBaseId ? `${RouteUrls.syncs}/${coreBaseId}` : RouteUrls.syncs;

  // http://localhost:3030/settings/dev-tools/job-viewer?externalBaseId=81c63c97-c84f-4dce-a852-e282a873b214

  static devToolRecordViewer(coreBaseId: string, recordId?: string): string {
    return (
      `${RouteUrls.syncDevTools(coreBaseId as UUID)}/record-history/` + assembleOptionalQueryString({ q: recordId })
    );
  }

  static devToolOldRecordViewer(
    coreBaseId: string,
    externalBaseId: string,
    externalTableId: string,
    remoteRecordId?: string,
  ): string {
    return (
      `${RouteUrls.syncDevTools(coreBaseId as UUID)}/records-old` +
      assembleOptionalQueryString({ externalBaseId, externalTableId, remoteRecordId })
    );
  }

  static publicRoutePatterns = [
    RouteUrls.v1SignInPageUrl,
    RouteUrls.v1SignUpPageUrl,
    RouteUrls.healthPageUrl,
    RouteUrls.helloApiUrl,
    RouteUrls.signIn,
    RouteUrls.signUp,
    RouteUrls.accountSignupPattern,
    RouteUrls.accountSignup,
  ];

  static subscriptionRoutePatterns = [
    RouteUrls.v1DashboardUrl,
    RouteUrls.v1LogsPageUrl,
    RouteUrls.v1IssuesPageUrl,
    RouteUrls.syncs,
    RouteUrls.issues,
    RouteUrls.operations,
    RouteUrls.connections,
    RouteUrls.getStarted,
  ];

  static isPublicRoute(pathname: string): boolean {
    return RouteUrls.publicRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }

  static isSubscribedOnlyRoute(pathname: string): boolean {
    return RouteUrls.subscriptionRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }
}

/**
 * Builds a query string from the paramters provided, filtering out any undefined or null values.
 
 * @param params an object of potential query string parameters
 * @returns a string in ?k=v&k2=v2... format OR an empty string if there are no valid parameters in the object
 */
export function assembleOptionalQueryString(
  params: Record<string, string | number | boolean | undefined | null>,
): string {
  const paramString = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string | number | boolean)}`)
    .join('&');

  return paramString && paramString.length > 0 ? `?${paramString}` : '';
}

/**
 * DevTool only. Creates an external link to the GCP cloud run logs explorer with the given query statements.
 *
 * @param args.service The sub-service to filter logs by
 * @param args.queryStatements The query statements to filter logs by
 *  @param args.duration The duration of logs to query, using a duration string (e.g. 'P7D' for 7 days)
 * @returns
 */
export function buildLogsExplorerURL(args: {
  service: 'worker' | 'jobqueue';
  queryStatements: Record<string, string>;
  duration?: string;
  flavor: Flavor;
}): string {
  const { service, queryStatements, duration, flavor } = args;

  let elements = '';
  Object.entries(queryStatements).forEach(([key, value]) => {
    elements += `${key} = "${value}"\n`;
  });

  const serviceName = service === 'worker' ? 'bottlenose-data-service' : 'bottlenose-job-queue';

  const query = encodeURIComponent(`resource.type = "cloud_run_revision"
  resource.labels.service_name = "${serviceName}"
  resource.labels.location = "us-central1"
  severity>=DEFAULT
  ${elements}`);

  return `https://console.cloud.google.com/logs/query;query=${query};storageScope=project;duration=${duration || 'P7D'}?project=wsv1-${flavor}`;
}
